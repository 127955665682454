'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useAccountStore } from '@/lib/client/store/account.store';
import { Loader } from '@/components/common/loaders/loaders';
import { useAuth } from '@/lib/client/hooks/useAuth';

export default function Home() {
  const router = useRouter();
  const { isLoggedIn, isLoading: isAuthLoading } = useAuth();
  const { type: accountType, isLoading: isAccountTypeLoading } =
    useAccountStore();

  const isLoading = isAuthLoading || isAccountTypeLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isLoggedIn) {
      return router.replace('/auth/sign-in');
    }

    if (!accountType) {
      return router.push(`/onboarding/mode`);
    }

    if (accountType === 'business') {
      return router.push(`/app/my-jobs`);
    }

    if (accountType === 'candidate') {
      router.push(`/app/explore`);
    }
  }, [isLoading, accountType, isLoggedIn, router]);

  return (
    <main className='h-screen/header-2 sm:h-screen/header flex items-center justify-center'>
      <Loader className='w-10 h-10 text-brand' />
    </main>
  );
}
